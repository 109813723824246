import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/compensationProjectTotal/page',
        method: 'GET',
        params
    });
}
// 查看详情
export function Detail(params) {
    return request({
        url: '/admin/compensationProject/page',
        method: 'GET',
        params
    });
}

// 添加项目拨款
export function Save(data) {
    return request({
        url: '/admin/compensationProject/save',
        method: 'POST',
        data
    });
}
// 修改项目拨款
export function Update(data) {
    return request({
        url: '/admin/compensationProject/update',
        method: 'POST',
        data
    });
}

// 分页获取-劳务队发放总费用
export function GetTeamlogs(params) {
    return request({
        url: '/admin/compensationTeamTotal/page',
        method: 'GET',
        params
    });
}

// 分页获取-劳务队拨款总费用
export function GetTeamList(params) {
    return request({
        url: '/admin/compensationTeamTotal/page',
        method: 'GET',
        params
    });
}

// 添加劳务队发放金额
export function teamSave(data) {
    return request({
        url: '/admin/compensationTeam/save',
        method: 'POST',
        data
    });
}

// 修改劳务队发放金额
export function teamUpdate(data) {
    return request({
        url: '/admin/compensationTeam/update',
        method: 'POST',
        data
    });
}

// 获取发放详情分页数据
export function GetDetailTeam(params) {
    return request({
        url: '/admin/compensationTeam/page',
        method: 'GET',
        params
    });
}


// 获取工种下拉数据
export function getWorkJson(params) {
    return request({
        url: '/admin/project/getWorkJson',
        method: 'GET',
        params
    });
}

// 获取项目下拉数据
export function getProJson(params) {
    return request({
        url: '/admin/project/page',
        method: 'GET',
        params
    });
}


