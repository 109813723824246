<template>
  <div class="page-cu-container">
    <div class="page-cu-top" ref="searchbox">
      <a-form layout="inline">
        <a-form-item>
          <a-select
            style="width: 350px"
            v-model="page.project_id"
            show-search
            placeholder="请选择项目"
            :filter-option="filterOption"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in proJson"
              :key="index"
              :value="item.id"
              :title="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
        <a-form-item>
          <a-button
            :icon="getExicon.icon"
            :disabled="getExicon.ed"
            @click="$exportFun('/export/compensationProjectTotal', page)"
            >导出</a-button
          >
        </a-form-item>
        <a-form-item v-if="is_financial">
          <a-button type="default" @click="addAction" icon="plus-circle"
            >拨款</a-button
          >
        </a-form-item>
      </a-form>
    </div>
    <div class="page-cu-main">
      <a-table
        :columns="columns"
        :data-source="list"
        rowKey="project_in_id"
        bordered
        :pagination="false"
        :scroll="{ y: tableHeigt }"
      >
        <template slot="state" slot-scope="text, record">
          <a-tag color="green" v-if="text == '1'">启用</a-tag>
          <a-tag color="orange" v-if="text == '2'">禁用</a-tag>
        </template>
        <template slot="sex" slot-scope="text, record">
          <span v-if="text == '0'">未知</span>
          <span v-if="text == '1'">男</span>
          <span v-if="text == '2'">女</span>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag v-if="text == '1'" color="orange"> 审核中 </a-tag>
          <a-tag v-if="text == '2'" color="green"> 已通过 </a-tag>
          <a-tag v-if="text == '3'" color="red"> 已拒绝 </a-tag>
        </template>
        <template slot="idno_positive_img_url" slot-scope="text, record">
          <img
            :src="text + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
            style="width: 90px; height: 60px"
            @click="
              imgvisible = true;
              viewImgData = text;
            "
            alt=""
          />
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button
            type="link"
            size="small"
            icon="import"
            @click="() => openeDetail(record)"
            >拨款详情</a-button
          >
          <a-button
            type="link"
            size="small"
            icon="export"
            @click="() => teamAction(record)"
            >发放记录</a-button
          >
        </template>
      </a-table>
      <div class="page-cu-pagination">
        <a-pagination
        :show-total="(total, range) => `总数：${total} 条`"
          :page-size.sync="page.pageSize"
          :total="page.totalRow"
          v-model="page.start"
          @change="changePage"
        />
      </div>
    </div>
    <!-- 新增/编辑发放弹框 -->
    <a-modal
      title="劳务队发放金额"
      :visible="true"
      v-if="addTeamVisible"
      @ok="addTeamData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="addTeamVisible = false"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="teamformModel"
        :rules="teamformModelRules"
        :model="teamform"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="劳务队" prop="team_id">
          <a-select
            show-search
            v-model="teamform.team_id"
            placeholder="请选择劳务队"
            :disabled="!isAdd"
            :filter-option="filterOption"
          >
            <a-select-option value="">请选择劳务队</a-select-option>
            <a-select-option
              v-for="(item, index) in teamJson"
              :key="index"
              :value="item.id"
              :title="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="发放金额" prop="grant_fee">
          <a-input
            v-model="teamform.grant_fee"
            suffix="元"
            placeholder="请输入发放金额"
          ></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 新增/编辑拨款弹框 -->
    <a-modal
      title="项目拨款金额"
      :visible="true"
      v-if="actionVisible"
      @ok="actionData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="formModel"
        :rules="formModelRules"
        :model="formModel"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="项目" prop="project_id">
          <a-select
            show-search
            v-model="formModel.project_id"
            placeholder="请选择项目"
            :disabled="!isAdd"
            :filter-option="filterOption"
          >
            <a-select-option value="">请选择项目</a-select-option>
            <a-select-option
              v-for="(item, index) in proJson"
              :key="index"
              :value="item.id"
              :title="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="拨款金额" prop="grant_fee">
          <a-input
            v-model="formModel.grant_fee"
            suffix="元"
            placeholder="请输入拨款金额"
          ></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 拨款详情 -->
    <a-modal
      title="拨款详情"
      width="1100px"
      :visible="vLogVisible"
      @ok="vLogClose"
      ok-text="确认"
      cancel-text="取消"
      @cancel="vLogClose"
      :confirmLoading="$store.state.requestLoading"
    >
      <div class="page-cu-top" style="padding-top: 0px">
        <a-form layout="inline">
          <a-form-item>
            <a-range-picker
              :placeholder="['拨款开始时间', '结束时间']"
              style="width: 400px !important"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              @change="getdTime"
            ></a-range-picker>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="getDetail" icon="search"
              >搜索</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button
              :icon="getExicon.icon"
              :disabled="getExicon.ed"
              @click="$exportFun('/export/compensationProject', vLogPage)"
              >导出</a-button
            >
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :columns="vLogColumns"
        :data-source="vLogList"
        :pagination="{
          total: vLogPage.totalRow,
          defaultPageSize: vLogPage.limit,
          defaultCurrent: 1,
        }"
        :scroll="{ x: 1000, y: 500 }"
        bordered
        rowKey="id"
      >
        <template slot="action" slot-scope="text, record">
          <a-button
          v-if="is_financial"
            type="link"
            size="small"
            icon="form"
            @click="() => editAction(record)"
            >编辑</a-button
          >
          <!-- <a-button
            type="link"
            size="small"
            icon="switcher"
            @click="() => teamAction(record)"
            >发放详情</a-button
          > -->
        </template>
      </a-table>
    </a-modal>

    <!-- 发放记录 -->
    <a-modal
      title="发放记录"
      width="1100px"
      :visible="teamVisible"
      @ok="teamVisible = false"
      ok-text="确认"
      cancel-text="取消"
      @cancel="teamVisible = false"
      :confirmLoading="$store.state.requestLoading"
    >
      <div class="page-cu-top" style="padding-top: 0px">
        <a-form layout="inline">
          <a-form-item>
            <a-select
              v-model="teamPage.team_id"
              show-search
              placeholder="请选择劳务队"
              style="width: 200px"
              :filter-option="filterOption"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option
                v-for="(item, index) in teamJson"
                :key="index"
                :value="item.id"
                :title="item.name"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="getTeamlogs" icon="search"
              >搜索</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button
              :icon="getExicon.icon"
              :disabled="getExicon.ed"
              @click="$exportFun('/export/compensationTeamTotal', teamPage)"
              >导出</a-button
            >
          </a-form-item>
          <a-form-item v-if="is_financial">
            <a-button type="default" @click="addTeamAction" icon="plus-circle"
              >发放</a-button
            >
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :columns="teamColumns"
        :data-source="teamList"
        :pagination="{
          total: teamPage.totalRow,
          defaultPageSize: teamPage.limit,
          defaultCurrent: 1,
        }"
        :scroll="{ x: 1000, y: 500 }"
        bordered
        rowKey="id"
      >
        <template slot="action" slot-scope="text, record">
          <a-button
            type="link"
            size="small"
            icon="copy"
            @click="() => detailTeamAction(record)"
            >发放详情</a-button
          >
        </template>
      </a-table>
    </a-modal>
    <!-- 发放详情 -->
    <a-modal
      title="发放详情"
      width="1100px"
      :visible="teamDetailVisible"
      @ok="teamDetailVisible = false"
      ok-text="确认"
      cancel-text="取消"
      @cancel="teamDetailVisible = false"
      :confirmLoading="$store.state.requestLoading"
    >
      <div class="page-cu-top" style="padding-top: 0px">
        <a-form layout="inline">
          <!-- <a-form-item>
            <a-select
              v-model="teamDetailpage.team_id"
              show-search
              placeholder="请选择劳务队"
              style="width: 200px"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option
                v-for="(item, index) in teamJson"
                :key="index"
                :value="item.id"
                :title="item.name"
                :filter-option="filterOption"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="getDetailTeamList" icon="search"
              >搜索</a-button
            >
          </a-form-item> -->
          <a-form-item>
            <a-button
              :icon="getExicon.icon"
              :disabled="getExicon.ed"
              @click="$exportFun('/export/compensationTeam', teamDetailpage)"
              >导出</a-button
            >
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :columns="teamDetailColumns"
        :data-source="teamDetailList"
        :pagination="{
          total: teamDetailpage.totalRow,
          defaultPageSize: teamDetailpage.limit,
          defaultCurrent: 1,
        }"
        :scroll="{ x: 1000, y: 500 }"
        bordered
        rowKey="id"
      >
        <template slot="action" slot-scope="text, record">
          <a-button
          v-if="is_financial"
            type="link"
            size="small"
            icon="form"
            @click="() => editTeamAction(record)"
            >编辑</a-button
          >
        </template>
      </a-table>
    </a-modal>
    <!-- 图片预览 -->
    <a-modal
      v-model="imgvisible"
      title="预览"
      ok-text="确认"
      @ok="hideModal"
      :footer="null"
    >
      <img
        style="width: 100%"
        :src="viewImgData + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
        alt=""
      />
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      isAdd: true, // TODO: 是否是新增操作
      formModel: {
        id: "",
        project_id: "",
        grant_fee: "",
      },
      formModelRules: {
        project_id: [
          { required: true, message: "请选择要拨款的项目", trigger: "change" },
        ],
        grant_fee: [
          { required: true, message: "请输入拨款金额", trigger: "change" },
        ],
      },
      tableHeigt: 0,
      vLogVisible: false,
      vLogColumns: [
        {
          title: "项目",
          dataIndex: "pname",
        },
        {
          title: "拨款金额",
          dataIndex: "grant_fee",
        },
        {
          title: "管理费用",
          dataIndex: "management_fee",
        },
        {
          title: "税金费用",
          dataIndex: "tax_fee",
        },
        {
          title: "剩余金额",
          dataIndex: "available_fee",
        },
        {
          title: "拨款时间",
          dataIndex: "create_time",
        },
        {
          title: "操作",
          width: 200,
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
      vLogList: [],
      vLogPage: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        project_id: "",
        start_time: "",
        end_time: "",
      },
      examineVisible: false,
      examineRules: {
        audit_state: [
          { required: true, message: "请选择审核结果", trigger: "change" },
        ],
      },
      viewImgData: "",
      examineData: {
        id: "",
        audit_state: undefined,
        refuse_reason: "",
      },
      imgvisible: false,
      isAdd: true, // TODO: 是否是新增操作
      columns: [
        {
          title: "项目名称",
          dataIndex: "pname",
        },
        // {
        //   title: "项目开始时间",
        //   dataIndex: "start_time",
        // },
        // {
        //   title: "项目结束时间",
        //   dataIndex: "end_time",
        // },
        {
          title: "总拨款",
          dataIndex: "total_grant_fee",
        },
        {
          title: "已发放",
          dataIndex: "total_actual_grant_fee",
        },
        {
          title: "管理费",
          dataIndex: "total_management_fee",
        },
        {
          title: "增值税",
          dataIndex: "total_tax_fee",
        },
        {
          title: "总剩余金额",
          dataIndex: "surplus_fee",
        },
        {
          title: "操作",
          width: 240,
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        project_id: undefined,
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
      jobJson: [], // 工种下拉数据
      proJson: [], // 项目下拉数据
      teamVisible: false,
      addTeamVisible: false,
      teamColumns: [
        {
          title: "项目名称",
          dataIndex: "pname",
        },
        {
          title: "劳务公司",
          dataIndex: "cname",
        },
        {
          title: "劳务队",
          dataIndex: "tname",
        },
        {
          title: "总发放金额",
          dataIndex: "total_grant_fee",
        },
        {
          title: "已使用金额",
          dataIndex: "total_actual_grant_fee",
        },
        {
          title: "剩余金额",
          dataIndex: "surplus_fee",
        },
        {
          title: "操作",
          width: 150,
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
      teamList: [],
      teamPage: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        project_id: undefined,
        team_id: undefined,
      },
      teamform: {
        id: "",
        project_id: "",
        team_id: "",
        grant_fee: "",
      },
      teamformModelRules: {
        team_id: [
          { required: true, message: "请选择要劳务队", trigger: "change" },
        ],
        grant_fee: [
          { required: true, message: "请输入发放金额", trigger: "change" },
        ],
      },
      teamJson: [],
      teamDetailpage: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        project_id: "",
        team_id: "",
        start_time: "",
        end_time: "",
      },
      teamDetailColumns: [
        {
          title: "项目名称",
          dataIndex: "pname",
        },
        {
          title: "劳务公司",
          dataIndex: "cname",
        },
        {
          title: "劳务队",
          dataIndex: "tname",
        },
        {
          title: "发放金额",
          dataIndex: "grant_fee",
        },

        {
          title: "发放时间",
          dataIndex: "create_time",
        },
        {
          title: "操作",
          width: 150,
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
      teamDetailVisible: false,
      teamDetailList: [],
      is_financial:false,
    };
  },
  computed: {
    getExicon() {
      return this.$store.state.EXICON;
    },
  },
  mounted() {
    this.getList();
    this.getWorkJson();
    this.getProJson();
    this.$nextTick(function () {
      this.tableHeigt =
        document.body.clientHeight - this.$refs.searchbox.offsetHeight - 170;
    });
     if(JSON.parse(window.localStorage.getItem("admin")).is_financial == "2"){
        this.is_financial = true;
    }else{
        this.is_financial = false;
    }
    console.log(this.is_financial)
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 获取工种下拉数据
    async getWorkJson() {
      let res = await Api.getWorkJson({ state: "1" });
      this.jobJson = res.list;
    },
    async getProJson() {
      let res = await Api.getProJson({ limit: 100000, state: "1" });
      this.proJson = res.page.list;
    },
    // 查看详情弹框
    openeDetail(data) {
      this.vLogPage.project_id = data.project_id;
      this.vLogVisible = true;

      this.getDetail();
    },
    async getDetail() {
      let res = await Api.Detail({ ...this.vLogPage });
      if (res && res["code"] == "0") {
        this.vLogList = res.page.list;
      } else {
        this.$message.error(res.message);
      }
    },
    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },

    // 重置密码确认
    async resetPassword(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要重置密码吗?",
        onOk() {
          this.resetPwd(id);
        },
        onCancel() {},
      });
    },

    async resetPwd(id) {
      let res = await Api.resetPwd({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },

    // 删除
    async del(id) {
      let res = await Api.Delete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      if (res && res.code == "0") {
        this.page.totalRow = res.page.totalRow;
        this.page.pageSize = res.page.pageSize;
        this.list = res.page.list;
      } else {
        this.list = [];
        this.$message.error(res.message);
      }
    },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    /**
     * 添加，更新项目拨款
     *
     */
    addAction() {
      this.actionVisible = true;
      this.isAdd = true;
      this.formModel.id = "";
      this.formModel.project_id = "";
      this.formModel.grant_fee = "";
    },
    editAction(data) {
      this.actionVisible = true;
      this.isAdd = false;
      this.formModel.id = data.id;
      this.formModel.project_id = data.project_id;
      this.formModel.grant_fee = data.grant_fee;
    },
    teamAction(data) {
      this.teamVisible = true;
      this.teamPage.project_id = data.project_id;
      this.teamform.project_id = data.project_id;
      this.teamJson = [];
      let teamarr = [];
      teamarr = data.team_Names.split(",");
      for (let i = 0; i < teamarr.length; i++) {
        this.teamJson.push({
          id: data.team_ids.split(",")[i],
          name: teamarr[i],
        });
      }
      this.getTeamlogs();
    },
    // 分页获取-劳务队拨款总费用
    async getTeamlogs() {
      let res = await Api.GetTeamlogs({ ...this.teamPage });
      if (res && res.code == "0") {
        this.teamList = res.page.list;
      } else {
        this.teamList = [];
        this.$message.error(res.message);
      }
    },
    // 新增劳务队发放金额
    addTeamAction() {
      this.addTeamVisible = true;
      this.isAdd = true;
      this.teamform.id = "";
      this.teamform.team_id = "";
      this.teamform.grant_fee = "";
    },
    // 编辑劳务队发放金额
    editTeamAction(data) {
      this.addTeamVisible = true;
      this.isAdd = false;
      this.teamform.id = data.id;
      this.teamform.team_id = data.team_id.toString();
      this.teamform.grant_fee = data.grant_fee;
    },
    addTeamData() {
      this.$refs.teamformModel.validate(async (valid) => {
        if (valid) {
          let res = null;
          if (this.teamform["id"]) {
            res = await Api.teamUpdate({ ...this.teamform });
          } else {
            res = await Api.teamSave({ ...this.teamform });
          }

          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.addTeamVisible = false;
            this.teamDetailpage.project_id = this.teamPage.project_id;
            this.getTeamlogs();
            this.getDetailTeamList();
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    actionData() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          let res = null;
          console.log(this.formModel);
          if (this.formModel["id"]) {
            res = await Api.Update(this.formModel);
          } else {
            res = await Api.Save(this.formModel);
          }

          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.actionVisible = false;
            if (this.formModel["id"]) {
              this.getList();
            } else {
              this.getList();
              this.getDetail();
            }
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    // 查看发放详情
    detailTeamAction(data) {
      this.teamDetailVisible = true;
      this.teamDetailpage.project_id = data.project_id;
      this.teamDetailpage.team_id = data.team_id;
      this.getDetailTeamList();
    },
    // 获取发放详情列表数据
    async getDetailTeamList() {
      let res = await Api.GetDetailTeam({ ...this.teamDetailpage });
      if (res && res.code == "0") {
        this.teamDetailpage.totalRow = res.page.totalRow;
        this.teamDetailpage.pageSize = res.page.pageSize;
        this.teamDetailList = res.page.list;
      } else {
        this.list = [];
        this.$message.error(res.message);
      }
    },
    hideModal() {
      this.imgvisible = false;
    },
    // 审核弹框
    async openexamineData(id) {
      this.examineData.id = id;
      this.examineVisible = true;
    },
    /**
     * 审核
     *
     */
    vLogClose() {
      this.vLogVisible = false;
    },
    // 关闭窗口
    cancel() {
      this.examineVisible = false;
      this.actionVisible = false;
    },
    getTime(t, v) {
      this.page.start_time = v[0];
      this.page.end_time = v[1];
    },
    getdTime(t, v) {
      this.vLogPage.start_time = v[0];
      this.vLogPage.end_time = v[1];
    },
  },
};
</script>

<style scoped>
</style>

